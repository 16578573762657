import * as React from "react";

function SvgComponent(props) {
  return (
   
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="170" height="200" viewBox="0 0 500.000000 500.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M1900 3269 c-322 -209 -596 -388 -610 -397 l-25 -16 30 -12 c36 -15
94 -18 127 -5 14 5 240 149 504 321 573 373 557 364 585 355 22 -7 139 -82
559 -357 484 -317 490 -321 542 -326 44 -4 109 12 108 27 -1 3 -233 157 -516
341 -628 408 -694 450 -708 450 -6 0 -275 -171 -596 -381z"/>
<path d="M2366 3195 c-68 -24 -135 -109 -136 -172 0 -22 3 -23 100 -23 l100 0
0 105 c0 115 1 113 -64 90z"/>
<path d="M2535 3198 c-3 -8 -5 -55 -3 -104 l3 -89 103 -3 c97 -3 102 -2 102
18 0 53 -56 137 -110 165 -44 22 -89 28 -95 13z"/>
<path d="M2230 2805 l0 -105 100 0 100 0 0 105 0 105 -100 0 -100 0 0 -105z"/>
<path d="M2540 2825 c0 -84 0 -86 -27 -95 -90 -33 -117 -73 -163 -239 -18 -67
-37 -118 -40 -114 -4 4 -29 56 -55 116 -26 59 -50 107 -54 107 -3 0 -19 -33
-35 -73 -17 -39 -39 -92 -49 -117 l-20 -44 -33 94 c-37 105 -40 126 -21 133 6
3 -26 6 -73 6 -74 1 -82 -1 -61 -12 20 -9 37 -45 84 -174 l60 -163 -29 -47
c-17 -28 -47 -57 -69 -70 -45 -25 -25 -31 29 -9 48 20 77 65 137 208 29 70 56
125 59 123 4 -2 31 -58 60 -125 52 -116 70 -142 70 -100 0 35 119 393 142 426
26 38 80 73 86 56 2 -8 36 -12 103 -12 l99 0 0 105 0 105 -100 0 -100 0 0 -85z"/>
<path d="M1686 2600 c-41 -13 -65 -38 -72 -77 -10 -53 11 -87 82 -134 111 -72
141 -130 91 -177 -50 -47 -143 -36 -178 22 -21 34 -25 86 -8 119 10 21 10 21
-5 3 -43 -53 -21 -143 42 -180 41 -23 127 -24 170 -2 68 35 96 108 66 171 -12
26 -41 53 -105 96 -86 57 -94 66 -89 99 5 45 96 56 128 15 l20 -25 6 32 c3 17
5 32 4 33 -10 8 -128 12 -152 5z"/>
<path d="M2555 2600 c-101 -8 -121 -14 -136 -42 -14 -26 13 -68 44 -67 l22 0
-22 9 c-16 6 -23 18 -23 35 0 28 5 30 63 39 l37 5 0 -175 c0 -172 0 -175 -22
-184 -17 -6 1 -9 62 -9 62 0 80 2 68 10 -16 9 -18 29 -18 190 0 177 0 180 23
189 12 5 15 8 7 8 -8 -1 -55 -4 -105 -8z"/>
<path d="M2853 2603 c-132 -4 -132 -4 -113 -23 18 -18 20 -34 20 -175 0 -142
-2 -157 -20 -175 l-20 -20 83 1 c59 0 76 3 60 9 -21 9 -23 16 -23 90 l0 80 89
0 c82 0 91 -2 105 -22 15 -22 16 -20 16 30 l0 52 -20 -20 c-17 -17 -33 -20
-105 -20 l-85 0 0 80 0 80 65 0 c50 0 66 -4 74 -17 8 -15 10 -11 11 20 0 20
-1 36 -2 35 -2 -1 -62 -4 -135 -5z"/>
<path d="M3243 2600 c-170 -5 -184 -6 -204 -27 -24 -23 -28 -68 -10 -86 10 -9
11 -6 6 14 -4 17 0 33 13 49 17 21 28 24 93 24 l74 1 3 -165 c3 -158 2 -166
-19 -182 -21 -17 -19 -18 62 -17 61 0 78 3 62 9 -23 9 -23 12 -23 185 l0 175
38 -1 c50 -1 72 -10 88 -40 13 -23 13 -23 14 18 0 23 -3 43 -7 45 -5 2 -90 1
-190 -2z"/>
<path d="M276 1977 c-5 -9 -6 -49 -4 -88 3 -60 8 -74 27 -90 33 -27 86 -25
116 6 22 22 25 32 25 105 0 47 -4 80 -10 80 -6 0 -10 -31 -10 -73 0 -89 -16
-117 -64 -117 -47 0 -60 21 -66 114 -4 53 -9 75 -14 63z"/>
<path d="M740 1885 c0 -63 4 -105 10 -105 6 0 10 13 10 28 0 16 8 36 18 45 19
18 42 23 42 10 1 -14 71 -84 81 -81 5 2 -11 28 -36 57 l-46 54 42 43 c24 24
39 47 36 51 -4 4 -34 -21 -66 -55 -32 -34 -61 -62 -64 -62 -4 0 -7 27 -7 60 0
33 -4 60 -10 60 -6 0 -10 -42 -10 -105z"/>
<path d="M1500 1885 c0 -59 4 -105 9 -105 6 0 11 15 13 33 3 31 5 32 57 37 56
6 81 25 81 63 0 48 -45 77 -121 77 l-39 0 0 -105z m120 65 c40 -40 16 -80 -53
-87 l-47 -6 0 57 0 56 40 0 c27 0 47 -7 60 -20z"/>
<path d="M1950 1885 c0 -63 4 -105 10 -105 6 0 10 16 10 35 0 33 2 35 34 35
27 0 38 -7 57 -35 13 -19 30 -35 38 -35 9 0 4 12 -13 35 -22 28 -24 36 -12 41
23 9 38 56 27 87 -11 28 -58 47 -118 47 l-33 0 0 -105z m122 67 c6 -6 11 -22
11 -37 0 -36 -13 -46 -67 -52 l-46 -6 0 58 0 58 46 -6 c25 -2 50 -9 56 -15z"/>
<path d="M2440 1979 c-39 -16 -60 -49 -60 -97 0 -81 77 -126 155 -90 32 14 55
56 55 97 0 66 -85 117 -150 90z m101 -38 c37 -38 39 -82 4 -116 -52 -53 -145
-16 -145 58 0 79 85 114 141 58z"/>
<path d="M2880 1885 c0 -63 4 -105 10 -105 6 0 10 16 10 35 l0 35 49 0 c70 0
103 38 82 93 -11 28 -59 47 -118 47 l-33 0 0 -105z m122 67 c6 -6 11 -22 11
-37 0 -36 -13 -46 -67 -52 l-46 -6 0 58 0 58 46 -6 c25 -2 50 -9 56 -15z"/>
<path d="M3320 1885 l0 -105 75 0 c43 0 75 4 75 10 0 6 -29 10 -66 10 l-65 0
3 38 3 37 55 6 55 5 -55 5 -55 4 -3 38 -3 37 60 0 c34 0 61 4 61 10 0 6 -30
10 -70 10 l-70 0 0 -105z"/>
<path d="M3758 1984 c-5 -4 -8 -52 -8 -106 0 -80 3 -98 15 -98 10 0 15 11 15
35 0 33 2 35 34 35 28 0 38 -6 56 -35 12 -19 26 -35 31 -35 14 0 11 9 -11 40
-19 27 -19 27 0 45 26 23 26 76 1 99 -20 18 -121 34 -133 20z m110 -30 c48
-33 13 -94 -55 -94 l-33 0 0 55 0 55 33 0 c18 0 43 -7 55 -16z"/>
<path d="M4170 1980 c0 -5 16 -10 35 -10 l35 0 0 -95 c0 -57 4 -95 10 -95 6 0
10 38 10 95 l0 95 41 0 c24 0 38 4 34 10 -3 6 -42 10 -86 10 -46 0 -79 -4 -79
-10z"/>
<path d="M4580 1983 c0 -4 18 -37 40 -74 23 -40 40 -80 40 -98 0 -17 5 -31 10
-31 6 0 10 18 10 40 0 28 10 55 35 94 34 53 45 83 26 71 -6 -3 -24 -30 -41
-60 l-32 -55 -39 60 c-36 56 -49 70 -49 53z"/>
<path d="M1274 1348 c-4 -7 -3 -8 5 -4 19 12 81 -14 122 -51 l39 -35 0 -169
c0 -167 0 -170 -22 -179 -17 -6 1 -9 62 -9 61 0 79 3 63 9 -22 9 -23 14 -23
132 l0 123 157 -164 c89 -93 172 -170 190 -177 40 -17 109 -18 119 -2 4 7 3 8
-5 4 -24 -15 -76 8 -128 57 l-53 50 0 169 c0 166 0 169 23 178 16 6 -2 9 -63
9 -61 0 -79 -3 -62 -9 21 -9 22 -14 22 -134 l0 -125 -83 87 c-46 48 -110 116
-142 151 -33 35 -77 72 -97 82 -41 21 -113 25 -124 7z"/>
<path d="M2514 1348 c-4 -7 -3 -8 5 -4 19 12 81 -14 122 -51 l39 -35 0 -169
c0 -167 0 -170 -22 -179 -17 -6 1 -9 62 -9 61 0 79 3 63 9 -22 9 -23 14 -23
132 l0 123 157 -164 c89 -93 172 -170 190 -177 40 -17 109 -18 119 -2 4 7 3 8
-5 4 -24 -15 -76 8 -128 57 l-53 50 0 169 c0 166 0 169 23 178 16 6 -2 9 -63
9 -61 0 -79 -3 -62 -9 21 -9 22 -14 22 -134 l0 -125 -83 87 c-46 48 -110 116
-142 151 -33 35 -77 72 -97 82 -41 21 -113 25 -124 7z"/>
<path d="M3384 1348 c-4 -7 -3 -8 5 -4 19 12 81 -14 122 -51 l39 -35 0 -169
c0 -167 0 -170 -22 -179 -17 -6 1 -9 62 -9 61 0 79 3 63 9 -22 9 -23 14 -23
132 l0 123 157 -164 c89 -93 172 -170 190 -177 40 -17 109 -18 119 -2 4 7 3 8
-5 4 -24 -15 -76 8 -128 57 l-53 50 0 169 c0 166 0 169 23 178 16 6 -2 9 -63
9 -61 0 -79 -3 -62 -9 21 -9 22 -14 22 -134 l0 -125 -83 87 c-46 48 -110 116
-142 151 -33 35 -77 72 -97 82 -41 21 -113 25 -124 7z"/>
<path d="M162 1331 c-10 -6 -7 -10 11 -15 14 -3 44 -27 66 -52 48 -54 48 -62
23 -229 -15 -99 -19 -111 -42 -122 -23 -12 -20 -13 34 -13 50 0 57 2 44 13
-14 11 -15 22 -2 116 7 57 16 106 18 108 2 3 29 -44 60 -104 30 -59 62 -117
70 -128 15 -19 15 -19 16 -1 0 25 112 218 121 209 3 -5 12 -44 19 -88 6 -44
20 -98 29 -120 34 -78 120 -128 188 -110 l28 8 -35 8 c-32 7 -90 60 -90 81 0
4 28 9 63 9 34 1 54 3 45 6 -22 6 -23 23 -1 68 17 35 17 35 83 35 l67 0 11
-37 c38 -128 164 -220 252 -184 22 8 22 10 5 10 -37 2 -85 35 -115 80 -16 25
-57 114 -92 199 -70 173 -108 227 -164 238 -20 4 -50 2 -67 -4 l-32 -10 28 -1
c32 -1 87 -50 87 -76 0 -23 -109 -275 -128 -297 -8 -10 -23 -18 -33 -18 -20 0
-23 15 -59 225 -13 72 -26 136 -30 144 -4 8 -34 -34 -85 -123 l-79 -135 -24
42 c-13 23 -39 72 -57 108 -19 36 -46 81 -61 99 -45 53 -135 85 -172 61z m798
-292 c0 -5 -22 -9 -50 -9 -59 0 -60 4 -24 86 l26 59 24 -64 c13 -35 24 -67 24
-72z"/>
<path d="M3165 1310 c-18 -8 -17 -9 6 -9 33 -1 92 -64 84 -90 -3 -9 -32 -77
-64 -151 -42 -94 -66 -139 -83 -147 -20 -11 -16 -13 37 -12 33 0 54 3 48 6
-16 6 -16 27 -1 70 12 33 12 33 79 33 l67 0 23 -57 c46 -114 147 -186 236
-168 l38 8 -32 7 c-79 18 -116 68 -194 263 -60 153 -100 222 -140 242 -31 16
-73 18 -104 5z m133 -192 c11 -29 23 -61 27 -70 6 -16 0 -18 -49 -18 -31 0
-56 2 -56 5 0 8 52 135 55 135 2 0 12 -24 23 -52z"/>
<path d="M1245 1290 c-101 -8 -121 -14 -136 -42 -14 -26 13 -68 44 -67 l22 0
-22 9 c-16 6 -23 18 -23 35 0 28 5 30 63 39 l37 5 0 -175 c0 -172 0 -175 -22
-184 -17 -6 1 -9 62 -9 62 0 80 2 68 10 -16 9 -18 29 -18 190 0 177 0 180 23
189 12 5 15 8 7 8 -8 -1 -55 -4 -105 -8z"/>
<path d="M2055 1290 c-114 -3 -188 -9 -201 -17 -36 -21 -45 -65 -19 -99 13
-18 14 -16 9 16 -9 53 19 73 107 78 l69 4 0 -176 c0 -174 0 -177 -22 -186 -17
-6 0 -9 60 -9 l82 -1 -20 20 c-19 19 -20 31 -18 183 l3 162 37 3 c44 4 76 -11
88 -41 7 -17 9 -12 9 26 1 26 0 46 -1 45 -2 -1 -84 -5 -183 -8z"/>
<path d="M2380 1291 c-58 -4 -97 -9 -87 -13 15 -7 17 -25 17 -183 0 -173 0
-176 -22 -185 -15 -6 34 -8 141 -7 l165 2 6 25 c4 14 10 39 14 55 l7 30 -21
-33 c-26 -42 -73 -62 -145 -62 l-55 0 0 80 0 81 57 -3 c50 -3 59 -6 70 -28 12
-23 13 -19 13 40 -1 54 -3 61 -12 45 -17 -30 -28 -35 -80 -35 l-48 0 0 80 0
80 29 0 c16 0 37 -7 45 -16 15 -14 16 -12 16 20 0 20 -1 35 -2 34 -2 -1 -50
-4 -108 -7z"/>
<path d="M4160 1289 c-64 -13 -130 -57 -154 -104 -29 -55 -27 -140 4 -189 60
-99 235 -137 332 -73 42 28 78 99 77 152 l-1 40 -9 -49 c-15 -92 -77 -146
-169 -146 -103 0 -164 64 -164 174 0 109 56 176 149 176 60 0 100 -22 124 -68
l20 -37 1 52 c0 49 -1 52 -32 62 -52 15 -129 20 -178 10z"/>
<path d="M4561 1292 c-97 -3 -103 -4 -83 -18 21 -14 22 -22 22 -175 0 -146 -2
-161 -20 -179 l-20 -20 159 0 159 0 5 29 c3 16 9 42 12 57 l7 29 -19 -30 c-32
-49 -49 -58 -128 -63 l-75 -5 0 82 0 82 57 -3 c50 -3 59 -6 70 -28 11 -23 13
-19 12 40 0 36 -3 58 -6 50 -10 -27 -36 -40 -85 -40 l-48 0 0 80 0 80 35 0
c27 0 37 -6 49 -27 l15 -28 1 42 c0 23 -3 43 -7 45 -5 2 -55 2 -112 0z"/>
</g>
</svg>

  );
}

export default SvgComponent;